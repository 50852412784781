import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Slider from "react-slick"

const HeaderSlider = () => {

  const data = useStaticQuery(graphql`
    query HeaderSlider {
      allStrapiHeaderSliders {
        edges {
          node {
            item {
              title
              subtitle
              description
            }
          }
        }
      }
    }
  `);


  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    arrows: false
  };


  return(
    <Slider {...settings} className="headerSlider">
      {
        data && data.allStrapiHeaderSliders.edges.map((node, index) => {
          const {title, subtitle, description} = node.node.item;

          return (
            <div key={index}>
              <h2 className="title textDefault text2md mdText2lg lgTextXlg mb1 mb3md">
                {title}
                {subtitle && <span className="textPrimary dBlock">{subtitle}</span> }
              </h2>
              {description && <p className="textDefault">{description}</p> }
            </div>
          )
        })
      }
    </Slider>
  )
}

export default  HeaderSlider
