import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import TemplateAboutUs from "../templates/templateAboutUs"
import { ROUTE } from "../general/constants"

const AboutUs = () => {
  const data = useStaticQuery(graphql`
    query AboutUsPreviewQuery {
      strapiAboutUs {
      Description
      Title {
        Title
        Subtitle
      }
      Image {
        publicURL
      }
      cta
    }
    }
 `);

  const {Title, Description, Image, cta} = data.strapiAboutUs;

  return (
    <TemplateAboutUs
      title={Title.Title}
      subtitle={Title.Subtitle}
      description={Description}
      image={Image.publicURL}
      anchor={ROUTE.ABOUT}
      cta={cta}
    />
  )
}

export default AboutUs
