import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import { header } from "../assets/images/images"
import Layout from "./layout"
import Footer from "./footer"
import HeaderSlider from "./headerSlider"
import { graphql, useStaticQuery } from "gatsby"

const HomepageLayout = ({ children }) => {

  const data = useStaticQuery(graphql`
    query  {
    strapiGeneralSettings {
        headerImage {
          publicURL
        }
      }
    }
  `)

  return (
    <>
      <section className="header overlay backgroundImage containerFluid" style={{ backgroundImage: `url(${data.strapiGeneralSettings ? data.strapiGeneralSettings.headerImage.publicURL: header})` }}>
        <Header />
        <div className="row">
          <div className="colMd10 offsetMd1 pb5lg pt5 pt200lg textCenter relative zIndex10">
            <HeaderSlider />
          </div>
        </div>
      </section>
      <main className="pb140md">{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default HomepageLayout
