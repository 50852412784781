import React, { useEffect, useState } from "react"
import { logo } from "../assets/images/images"
import { graphql, Link, useStaticQuery } from "gatsby"
import { ROUTE } from "../general/constants"

const Header = () => {

  const data = useStaticQuery(graphql`
    query getHeaderSettings {
      strapiGeneralSettings {
        Address
        Email
        Phone
      }
    }
  `);

  const {Address, Email, Phone} = data.strapiGeneralSettings;

  const [isMenuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    if (isMenuOpen) {
      return document.body.classList.add("noScroll")
    }
    return document.body.classList.remove("noScroll")
  }, [isMenuOpen])

  return (
    <div className="row">
      <header className="navbar col">
        <nav className="navbarNav">
          <div className="dFlex alignItemsCenter justifyContentBetween">
            <div className="flex1">
              <div className={`navbarNavBurger hiddenOnLgUp ${isMenuOpen ? "navbarNavBurgerActive" : ""}`}
                   onClick={() => setMenuOpen(!isMenuOpen)}>
                <span /><span /><span /><span />
              </div>
              <div className="dFlex textDefault flex1 hidden showOnLgUp">
                <i className="fas fa-map-marker-alt mt05 mr1 textMd" />
                {Address}
              </div>
            </div>
            <div className="flex1">
              <Link to={"/"} className="navbarBrand">
                <img src={logo} className="dBlock" alt="Logo" />
              </Link>
            </div>
            <div className="flex1 dFlex flexColumn justifyContentEnd justifyContentLgCenter">
              <a href={`tel:0${Phone}`} className="linkUnstyled" title="Apeleaza Passion">
                <div className="dInlineFlex alignItemsMdCenter justifyContentCenter flexColumn flexMdRow textDefault mb1">
                  <i className="fas fa-phone-alt mb1 mb0md mr1md textMd" />
                  0{Phone}
                </div>
              </a>
              <div className="dInlineFlex alignItemsMdCenter flexColumn flexMdRow textDefault mb1 hiddenOnMobile">
                <i className="far fa-envelope mb1 mb0md mr1md textMd" />
                {Email}
              </div>
            </div>
          </div>
          <ul className={`navbarItems  ${isMenuOpen ? "navbarItemsActive" : ""}`}>
            <li className="navbarItem">
              <Link to={ROUTE.HOME} className="navbarLink" activeClassName="textPrimary">Home</Link>
            </li>
            <li className="navbarItem">
              <Link to={ROUTE.ABOUT} className="navbarLink" activeClassName="textPrimary">Despre noi</Link>
            </li>
            <li className="navbarItem">
              <Link to={ROUTE.MENU} className="navbarLink" target="_blank" activeClassName="textPrimary">Meniu</Link>
            </li>
            <li className="navbarItem">
              <Link to={ROUTE.GALLERY} className="navbarLink" activeClassName="textPrimary">Galerie</Link>
            </li>
            <li className="navbarItem">
              <Link to={ROUTE.BLOG} className="navbarLink" activeClassName="textPrimary">Blog</Link>
            </li>
            <li className="navbarItem mb5 mb0md">
              <Link to={ROUTE.CONTACT} className="navbarLink" activeClassName="textPrimary">Contact</Link>
            </li>
            <li>
              <div className="textCenter textDefault hiddenOnSmUp">
                <i className="far fa-envelope mb1 textMd" />
                {Email}
              </div>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  )
}

export default Header
