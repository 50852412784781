import React from 'react'
import { graphql, useStaticQuery } from "gatsby"

const Facilities = () => {

  const data = useStaticQuery(graphql`
    query FacilitiesQuery {
      strapiFacilities {
        Title {
          Title
          Subtitle
        }
        Items {
          Name
          icon {
            publicURL
          }
        }
        Image {
          publicURL
        }
      }
    }
 `);
  const {Title, Items, Image} = data.strapiFacilities;

  return (
    <section className="py5 py140md container">
      <div className="row relative zIndex10 alignItemsCenter">
        <div className="colMd5 order2 orderMd1">
          <div className="sectionImage sectionImageLeft mb5 mb0md">
            <img src={Image.publicURL} className="imgFluid" alt=""/>
          </div>
        </div>
        <div className="colMd7 textCenter order1 orderMd2">
          <h2 className="sectionTitle">
            {Title.Title} <span>{Title.Subtitle}</span>
          </h2>
          <ul className="listUnstyled row mb3 mb0md">
            {
              Items.map((item, index) => {
                const {Name, icon} = item;
                return (
                  <li key={index} className="colSm6 mb3 mb5md">
                    <div className="pt3 pb2 sectionFacilitiesItem">
                      <img src={icon.publicURL} alt={Name} className="mb1 w3" />
                      <span className="textSecondaryDark weightMedium dBlock">{Name}</span>
                    </div>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Facilities
