export const ROUTE = {
  HOME: '/',
  ABOUT: '/despre-noi',
  GALLERY: '/galerie',
  CONTACT: '/contact',
  BLOG: '/blog',
  MENU: 'https://www.novamenu.ro/restaurant/view-menu/2/'
};


export const trimUrl = (url) => {
  return url && url.normalize("NFD")
  .replace(/[\u0300-\u036f]/g, "")
  .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,"")
  .replace(/\s+/g, '-').toLowerCase();
}
