import React  from 'react'
const marked = require("marked");

const Services = ({data}) => {
  const {title, item} = data;

  return (
    <section className="py5 py140md backgroundSecondaryDark textDefault">
      <div className="container">
        <div className="row">
          <div className="col">
            <h2 className="sectionTitle textDefault pb5 textCenter">
              {title.Title && title.Title} <span>{title.Subtitle && title.Subtitle}</span>
            </h2>
          </div>
        </div>
        <div className="row">
          {
            item && item.map((service, index) => {
              const {title, description, image} = service;

              return (
                <div className="colMd6 colLg4 mb5" key={index}>
                  <div className="sectionServicesItem">
                    <figure className="relative mb15 mb3md">
                      <img src={image.childImageSharp && image.childImageSharp.fixed.src} alt={title} className="imgFluid relative zIndex10"/>
                    </figure>
                    <h3 className="sectionServicesItemTitle textDefault mb15">{ title }</h3>
                      <div dangerouslySetInnerHTML={{__html: marked(description)}} />
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </section>
  )
}

export default Services
