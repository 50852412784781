import React from "react"
import { graphql } from "gatsby"

import AboutUs from "../components/aboutUs"
import Services from "../components/services"
import Facilities from "../components/facilities"

import HomepageLayout from "../components/homepageLayout"
import Testimonials from "../components/testimonials"
import BlogPreview from "../components/blogPreview"
import Contact from "../components/contact/contact"
import { Helmet } from "react-helmet"

const IndexPage = ({ data }) => (
  <HomepageLayout>
    <Helmet>
        <title>Acasa</title>
    </Helmet>
    <AboutUs />
    <Services data={data.strapiServices} />
    <Facilities />
    <Testimonials />
    <BlogPreview data={data} />
    <Contact />
  </HomepageLayout>
)

export default IndexPage

export const pageQuery = graphql`
    query BlogPreview {
      strapiServices {
        title {
          Title
          Subtitle
        }
        item {
          title
          description
          image {
            publicURL
            childImageSharp {
              fixed(width: 300, fit: COVER, height: 200) {
                src
              }
            }
          }
        }
      }
      strapiBlog {
        title {
          Title
          Subtitle
        }
        cta
      }
      allStrapiBlogPages(sort: {fields: createdAt, order: DESC}, limit: 3) {
        edges {
          node {
            id
            title
            date(formatString: "DD MMMM YYYY")
            content
            image {
              childImageSharp {
                fixed(width: 350, height: 233, quality: 100, fit: FILL) {
                  src
                }
              }
            }
          }
        }
      }
    }
`
