import React from "react"
import { Link } from "gatsby"
import { ROUTE, trimUrl } from "../general/constants"

const BlogPreview = ({data}) => {
  const {title, cta} = data.strapiBlog;
  const {edges} = data.allStrapiBlogPages;

  return (
    <section className="py5 py140md">
      <div className="container textCenter">
        <div className="row">
          <div className="col">
            <h2 className="sectionTitle">{title.Title} <span>{title.Subtitle}</span></h2>
          </div>
        </div>
        <div className="row mb3 mb5md">
          {
            edges && edges.map((item, index) => {
              const {title, image, date} = item.node;

              return(
                <div key={index} className="colMd4 mb3 textCenter">
                  <img src={image.childImageSharp.fixed.src} className="imgFluid mb1" alt="" />
                  <p className="textUppercase mb1">{date}</p>
                  <Link to={`blog/${trimUrl(title)}`} className="linkUnstyled textSecondaryDark textMd fontSecondary textUppercase blogTitle">
                    {title}
                  </Link>
                </div>
              )
            })
          }
        </div>
        <div className="row mb1 mb0md">
          <div className="col"><Link to={ROUTE.BLOG} className="btn btnPrimary">{cta}</Link></div>
        </div>
      </div>
    </section>
  )
}

export default BlogPreview
