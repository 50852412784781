import React from "react"
import { header, logo } from "../assets/images/images"
import { graphql, Link, useStaticQuery } from "gatsby"

const Footer = () => {

  const data = useStaticQuery(graphql`
    query getFooterSettings {
      strapiGeneralSettings {
        Address
        Email
        Phone
        Description
        openingHoursLabel
        reservationNumberLabel
        Opening {
          day
          hours
        }
        SocialMedia {
          icon {
            publicURL
          }
          url
        }
      }
    }
  `)

  const { Address, Email, Phone, Opening, SocialMedia, Description, reservationNumberLabel, openingHoursLabel } = data.strapiGeneralSettings

  return (
    <>
      <footer className="overlay relative textCenter backgroundImage py5" style={{ backgroundImage: `url(${header})` }}>
        <div className="container relative zIndex10">
          <div className="row alignItemsCenter">
            <div className="colMd7 mb3">
              <div className="dFlex alignItemsCenter justifyContentBetween mb15">
                <img src={logo} alt="" className="footerLogo" />
              </div>
              <p className="textLeft textDefault">{Description}</p>
              <hr className="my2" />
              <ul className="listUnstyled dFlex flexWrap alignItemsStart textLeft mb3">
                {
                  Address && <li className="dFlex textDefault mb15 mb0md flex1 pr1">
                    <i className="fas fa-map-marker-alt mt05 mr1 textMd" />
                    {Address}
                  </li>
                }
                {
                  Email && <li className="dFlex textDefault mb15 mb0md flex1 pr1">
                    <i className="far fa-envelope mt05 mr1 textMd" />
                    {Email}
                  </li>
                }
              </ul>
              <hr className="my2" />
              <ul className="listUnstyled listSocial dFlex alignItemsCenter">
                <li>
                  {
                    Phone && <span className="dFlex textDefault flex1 pr2">
                    <i className="fas fa-phone-alt mr1 textMd" />
                    0{Phone}
                  </span>
                  }
                </li>
                {
                  SocialMedia && SocialMedia.map((social, index) => {
                    return (
                      <li key={index} className="mr15">
                        <Link
                          className="listSocialLink"
                          target="_blank"
                          to={social.url}
                        >
                          <img src={social.icon.publicURL} alt="" />
                        </Link>
                      </li>
                    )
                  })
                }
              </ul>
              <hr className="my2" />
              <ul className="listUnstyled listSocial dFlex alignItemsCenter">
                <li className="mr15"><Link className="linkUnstyled" to={'/politica-confidentialitate'} target="_blank" rel="noopener noreferrer"> Politica de confidențialitate </Link></li>
                <li><Link to={'/politica-cookies'} className="linkUnstyled" target="_blank" rel="noopener noreferrer"> Politica de cookies </Link></li>
              </ul>
            </div>
            <div className="colLg4 offsetLg1 colMd5">
              <div className="contact backgroundSecondaryDark hFull px15 py15">
                <div className="contactWidget px1 py15">
                  <h3 className="fontSecondary text1md textDefault mb15">{openingHoursLabel}</h3>
                  <ul className="listUnstyled listOpenHours mb2">
                    {
                      Opening && Opening.map((open, index) => {
                        const {day, hours} = open;
                        return (
                          <li key={index}>
                            <span className="listOpenHoursDay">{day}</span>
                            <span className="listOpenHoursHour">{hours}</span>
                          </li>
                        )
                      })
                    }
                  </ul>
                  <h3 className="fontSecondary text1md textDefault mb1">{reservationNumberLabel}: </h3>
                  <a href={`tel:0${Phone}`} className="textUnderlineNone text2md textPrimary">0{Phone}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="textCenter py15 backgroundSecondary weightMedium textDefault textSm">
        Powered by <span className="textPrimary">NovaCore</span>
      </div>
    </>
  )
}

export default Footer
